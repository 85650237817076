require('@fontsource/roboto');
require('@fontsource/roboto/latin-300.css');
require('@fontsource/roboto/latin-ext-300.css');
require('@fontsource/roboto/latin-500.css');
require('@fontsource/roboto/latin-ext-500.css');
require('@fontsource/roboto/latin-700.css');
require('@fontsource/roboto/latin-ext-700.css');
require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');
require('./src/style/app.scss');

exports.onInitialClientRender = () => {
    window.scrollTo(0, 0);
};

exports.shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => [0, 0];
