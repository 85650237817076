// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasi-tworcy-js": () => import("./../../../src/pages/nasi-tworcy.js" /* webpackChunkName: "component---src-pages-nasi-tworcy-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-projectstart-js": () => import("./../../../src/pages/projectstart.js" /* webpackChunkName: "component---src-pages-projectstart-js" */),
  "component---src-pages-raport-tiktok-js": () => import("./../../../src/pages/raport-tiktok.js" /* webpackChunkName: "component---src-pages-raport-tiktok-js" */),
  "component---src-pages-webinary-js": () => import("./../../../src/pages/webinary.js" /* webpackChunkName: "component---src-pages-webinary-js" */),
  "component---src-pages-wiedza-js": () => import("./../../../src/pages/wiedza.js" /* webpackChunkName: "component---src-pages-wiedza-js" */),
  "component---src-templates-case-study-js": () => import("./../../../src/templates/case-study.js" /* webpackChunkName: "component---src-templates-case-study-js" */),
  "component---src-templates-o-nas-js": () => import("./../../../src/templates/o-nas.js" /* webpackChunkName: "component---src-templates-o-nas-js" */)
}

